import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Router } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { LeftsidebarComponent } from './components/leftsidebar/leftsidebar.component';
import { TopbarComponent } from './components/topbar/topbar.component';

import { HttpClientModule, HttpClient } from '@angular/common/http';

import { FormsModule }   from '@angular/forms';
import { ZonesComponent } from './components/pages/zones/zones.component';
import { AddZoneModelComponent } from './components/add-zone-model/add-zone-model.component';
import { EditZoneModalComponent } from './components/edit-zone-modal/edit-zone-modal.component';
import { OrdersComponent } from './components/pages/orders/orders.component';
import { SingleOrederModalComponent } from './components/single-oreder-modal/single-oreder-modal.component';
import { StatusModalComponent } from './components/status-modal/status-modal.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { HomeComponent } from './components/pages/home/home.component';
import { AddColorComponent } from './components/pages/add-color/add-color.component';
import { NotificationComponent } from './components/notification/notification.component';
import { BranchComponent } from './components/pages/branch/branch.component';
import { EditeBranchComponent } from './components/edite-branch/edite-branch.component';
import { AddBranchComponent } from './components/add-branch/add-branch.component';
import { CategoriesComponent } from './components/pages/categories/categories.component';
import { ProductsComponent } from './components/pages/products/products.component';
import { SingleProductCategoryComponent } from './components/single-product-category/single-product-category.component';
import { AddCategoryComponent } from './components/add-category/add-category.component';
import { EditeCategoryComponent } from './components/edite-category/edite-category.component';
import { EditeProductComponent } from './components/edite-product/edite-product.component';
import { AddProductComponent } from './components/add-product/add-product.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LeftsidebarComponent,
    TopbarComponent,
    ZonesComponent,
    AddZoneModelComponent,
    EditZoneModalComponent,
    OrdersComponent,
    SingleOrederModalComponent,
    StatusModalComponent,
    HomeComponent,
    AddColorComponent,
    NotificationComponent,
    BranchComponent,
    EditeBranchComponent,
    AddBranchComponent,
    CategoriesComponent,
    ProductsComponent,
    SingleProductCategoryComponent,
    AddCategoryComponent,
    EditeCategoryComponent,
    EditeProductComponent,
    AddProductComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
