import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { AddColorComponent } from './components/pages/add-color/add-color.component';
import { BranchComponent } from './components/pages/branch/branch.component';
import { CategoriesComponent } from './components/pages/categories/categories.component';
import { HomeComponent } from './components/pages/home/home.component';
import { OrdersComponent } from './components/pages/orders/orders.component';
import { ProductsComponent } from './components/pages/products/products.component';
import { ZonesComponent } from './components/pages/zones/zones.component';


const routes: Routes = [
  { path: '',pathMatch: 'full', redirectTo: '/home' },
  { path: 'login', component: LoginComponent },
  { path: 'zones', component: ZonesComponent },
  { path: 'orders', component: OrdersComponent },
  { path: 'home', component: HomeComponent },
  { path: 'addColor', component: AddColorComponent },
  { path: 'branch', component: BranchComponent },
  { path: 'categories', component: CategoriesComponent },
  { path: 'products', component: ProductsComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    { scrollPositionRestoration :'top'},
    
    )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
