  <!-- ========== Left Sidebar Start ========== -->
  <div class="left side-menu">
    <div class="slimscroll-menu" id="remove-scroll">
        <!-- LOGO -->
        <div class="topbar-left">
            <a routerLink="/home" class="logo">
                <img src="../../../assets/images/logo.png">
            </a>
        </div>
        <!-- User box -->
        <div class="user-box">
            <div class="user-img">
                <img src="assets/images/profile.png" alt="user-img" title="Mat Helme" class="rounded-circle img-fluid">
            </div>
            <h5><a href="#">{{userName}}</a> </h5>
            <p class="text-muted">{{email}}</p>
        </div>

        <!--- Sidemenu -->
        <div id="sidebar-menu">
            <ul class="metismenu" id="side-menu">
                <li>
                    <a routerLink="/home"  routerLinkActive="active">
                        <i class="fi-air-play"></i> <span> Dashboard </span>
                    </a>
                </li>
                <li>
                    <a routerLink="/zones"  routerLinkActive="active">
                        <i class="mdi mdi-map-marker-multiple"></i> <span> Zones </span>
                    </a>
                </li>
                <li>
                    <a routerLink="/orders"  routerLinkActive="active">
                        <i class="fi-box"></i> <span> All Orders </span>
                    </a>
                </li>
                <li>
                    <a routerLink="/categories"  routerLinkActive="active">
                        <i class="fi-layout"></i> <span> Categories </span>
                    </a>
                </li>
                <li>
                    <a routerLink="/products"  routerLinkActive="active">
                        <i class="fi-layout"></i> <span> Products </span>
                    </a>
                </li>
                <li>
                    <a routerLink="/branch"  routerLinkActive="active">
                        <i class="fi-layout"></i> <span> Branch </span>
                    </a>
                </li>
                <li>
                    <a routerLink="/addColor"  routerLinkActive="active">
                        <i class="fi-cog "></i> <span> Settinges </span>
                    </a>
                </li>
                
            </ul>
        </div>
        <!-- Sidebar -->
        <div class="clearfix"></div>
    </div>
    <!-- Sidebar -left -->
</div>
<!-- Left Sidebar End -->